<template>
  <LargeLayout>
    <div class="col-12 p-0">
      <Map />
    </div>
    <!-- Description -->
    <div
      class="
        col-12
        row
        home-body-content__what-news
        my-2
        d-none d-lg-flex
        align-items-center
      "
    >
      <div class="col-12 col-md-4 what-news__img">
        <img alt="wuDN" src="@/assets/whatnew.png" class="w-100 h-100" />
      </div>
      <div class="col-12 col-md-8 what-news__text-box">
        <p class="what-news__text ps-3 small mb-0">
          {{ $t("home.description") }}
        </p>
      </div>
    </div>
    <div class="col-12 p-0">
      <h4
        class="
          bg-success
          text-white
          font-weight-bold
          p-2
          my-3
          text-uppercase text-center text-lg-left
        "
      >
        {{ $t("home.apartment") }}
      </h4>
      <NewApartment />
    </div>
    <div class="col-12 p-0 d-none d-lg-block">
      <NewPost />
    </div>
  </LargeLayout>
</template>

<script>
import LargeLayout from "../../layout/main/LargeLayout.vue";
import Map from "./components/Map.vue";
import NewApartment from "./components/NewApartment.vue";
import NewPost from "./components/NewPost.vue";
export default {
  components: { LargeLayout, NewApartment, Map, NewPost },
  data() {
    return { locale: "ja" };
  },
  watch: {
    locale(val) {
      this.$i18n.locale = val;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.what-news__text-box {
  border-left: 6px solid var(--success);
}
</style>
