<template>
  <div class="col-12 home_map p-0 position-relative">
    <div
      class="
        card
        text-white text-center
        overflow-hidden
        border-radius-lg border-3 border-success
        bg-success
      "
    >
      <div
        class="
          card-header
          font-weight-bold
          text-uppercase
          h4
          bg-success
          border-3 border-success
        "
      >
        {{ $t("map.Search By Area") }}
      </div>
      <div class="card-body p-0 map-wrapper">
        <img alt="header" src="@/assets/map/map.png" class="w-100 h-100" />
      </div>
    </div>
    <div>
      <button
        v-for="(item, index) in areas"
        :key="index"
        class="position-absolute map-btn"
        :class="`btn-${item}`"
      >
        <router-link :to="`explore/${item}`" class="map-link">
          <span class="number rounded-circle">{{ item }}</span>
        </router-link>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Map",
  data() {
    return { areas: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11] };
  },
};
</script>

<style lang="scss" scoped>
.map-link:hover {
  text-decoration: none;
  color: inherit;
}
.home__map {
  .card {
    border: 3px solid var(--success) !important;
  }
  .card-body {
    padding: 15px;
    border-top: 0;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .card-header {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    position: relative;
    top: 20px !important;
  }
}
button {
  border: 0;
  background: transparent;
}
span.number {
  background: var(--orange);
  color: white;
  font-weight: 700;
  font-size: 2.2rem;
  width: 44px !important;
  height: 44px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .map-wrapper {
    height: 684px;
    // max-height: 800px;
    min-width: 684px;
  }
}

@media screen and (max-width: 768px) {
  span.number {
    font-size: 1.5rem;
    width: 38px !important;
    height: 38px !important;
  }
}

@media screen and (max-width: 500px) {
  span.number {
    font-size: 1rem;
    width: 21px !important;
    height: 21px !important;
  }
  .mapp-wrapper {
    min-height: 50vh;
    min-width: 100vw;
  }
  .btn-1 {
    top: 37% !important;
    left: 35% !important;
  }
  .btn-2 {
    top: 34% !important;
    left: 56% !important;
  }
  .btn-3 {
    top: 47% !important;
    left: 37% !important;
  }
  .btn-4 {
    top: 47% !important;
    left: 59% !important;
  }
  .btn-5 {
    top: 57% !important;
    left: 36% !important;
  }
  .btn-6 {
    top: 54% !important;
    left: 63% !important;
  }
  .btn-7 {
    top: 74% !important;
    left: 36% !important;
  }
  .btn-8 {
    top: 64% !important;
    left: 64% !important;
  }
  .btn-9 {
    top: 80% !important;
    left: 69% !important;
  }
  .btn-10 {
    top: 28% !important;
    left: 67% !important;
  }
  .btn-11 {
    top: 44% !important;
    left: 17% !important;
  }
}

.btn-1 {
  top: 33%;
  left: 37%;
}
.btn-2 {
  top: 30%;
  left: 58%;
}
.btn-3 {
  top: 45%;
  left: 38%;
}
.btn-4 {
  top: 43%;
  left: 61%;
}
.btn-5 {
  top: 55%;
  left: 37%;
}
.btn-6 {
  top: 51%;
  left: 64%;
}
.btn-7 {
  top: 74%;
  left: 36%;
}
.btn-8 {
  top: 60%;
  left: 67%;
}
.btn-9 {
  top: 79%;
  left: 71%;
}
.btn-10 {
  top: 23%;
  left: 68%;
}
.btn-11 {
  top: 41%;
  left: 17%;
}
</style>
