<template>
  <div class="row new-properties__content">
    <div
      class="col-property property-wrapper p-0"
      v-for="(property, index) in properties"
      :key="index"
    >
      <div class="card border-radius-md property border-0">
        <router-link
          v-bind:to="'/property/' + property._id"
          class="property_link"
        >
          <div
            class="
              card-header
              text-white text-center
              bg-success
              text-uppercase
              font-weight-bold
            "
          >
            {{ property.title }}
          </div>
        </router-link>
        <div class="card-body">
          <div class="row p-0">
            <div class="col-12">
              <router-link
                v-bind:to="'/property/' + property._id"
                class="property_link"
              >
                <img
                  :src="url + property.images[0]"
                  class="card-body__image"
                />
              </router-link>
            </div>
          </div>
          <div class="row">
            <div class="col-6 card-body__text">
              <p>{{ $t("property.Floor plan") }}</p>
            </div>
            <div class="col-6 text-uppercase">
              <p>{{ property.plan }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-6 card-body__text">
              <p>{{ $t("property.Cost") }}</p>
            </div>
            <div class="col-6">
              <p>{{ property.cost }} $</p>
            </div>
          </div>
          <div class="row">
            <div class="col-6 card-body__text">
              <p>{{ $t("property.Area") }}</p>
            </div>
            <div class="col-6">
              <p>{{ property.square }} m&#178;</p>
            </div>
          </div>
          <div class="row">
            <div class="col-6 card-body__text">
              <p class="">{{ $t("property.Bedroom") }}</p>
            </div>
            <div class="col-6">
              <p>{{ property.bedroom }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-6 card-body__text">
              <p>{{ $t("property.Bathroom") }}</p>
            </div>
            <div class="col-6">
              <p>{{ property.bathroom }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosConfig from "../../../libs/axiosConfig";
export default {
  name: "newproperty",
  data() {
    return {
      url: process.env.VUE_APP_API_URL,
      properties: [],
    };
  },
  created() {
    axiosConfig
      .get("/property-limit")
      .then((response) => {
        this.properties = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style lang="scss" scoped>
.property {
  .card-body {
    padding: 15px;
    border: 3px solid var(--success);
    border-top: 0;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .card-header {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    white-space: nowrap;
  }
}

@media screen and (max-width: 767px) {
  .property-wrapper:last-child {
    display: none;
  }
  .new-properties__content {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)) !important;
    grid-gap: 10px !important;
    margin: 0;
    font-size: 0.8rem;
  }
  .col-property {
    .card {
      .card-body {
        padding: 10px !important;
        .card-body__image {
          max-width: 100%;
          max-height: 100%;
          object-fit: cover;
          height: 120px !important;
        }
        .row {
          .col-5 {
            p {
              text-align: justify !important;
              font-size: 0.6rem;
            }
          }
          .col-7 {
            p {
              font-size: 0.6rem;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1023px) and (min-width: 768px) {
  .property-wrapper:last-child {
    display: none;
  }
  .new-properties__content {
    display: grid;
    grid-template-columns: repeat(auto-fit, 1fr) !important;
    grid-gap: 10px !important;
    margin: 0;
    font-size: 0.8rem;
  }
  .col-property {
    .card {
      .card-body {
        padding: 10px;

        .card-body__image {
          height: 150px !important;
          object-fit: cover;
        }
        .row {
          .col-5 {
            p {
              text-align: justify !important;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) and (min-width: 367px) {
  .text-wrapper {
    min-height: 17px
  }
  .text-small {
    font-size: 10px !important;
  }
}

@media screen and (max-width: 320px) {
  .col-property {
    .card {
      .card-body {
        .card-body__image {
          height: 100px !important;
        }
        p {
          font-size: 0.5rem !important;
        }
      }
    }
  }
}

.property_link:hover {
  text-decoration: none;
  color: inherit;
}
.new-properties__content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 15px;
  margin: 0;
  .col-property {
    .card {
      .card-header {
        overflow: hidden !important;
        text-overflow: ellipsis !important;
      }
      .card-body {
        padding: 15px;
        width: 100%;
        height: auto;
        .card-body__image {
          margin-bottom: 10px;
          width: 100%;
          height: 150px;
          object-fit: cover;
        }
        .row {
          padding-left: 10px;
          align-items: center;
          p {
            margin: 0px;
            white-space: nowrap;
            font-size: 0.7rem;
          }
          .card-body__text {
            border-right: 1px solid var(--success);
            background-color: var(--bg);
            padding-left: 5px;
            padding-right: 5px;
            p {
              width: 100%;
              text-align-last: justify;
            }
          }
        }
      }
    }
  }
}
</style>
